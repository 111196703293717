<template>
  <b-row class="preview-center" no-gutters>
    <b-col>
      <img
        class="img-cover-radius"
        v-if="src"
        height="65"
        width="65"
        :src="src"
        alt
      />
      <img
        v-else
        height="50"
        width="80"
        src="@/assets/images/playground/ban.svg"
        alt
      />
    </b-col>
  </b-row>
</template>

<script>
export default {
  data: () => ({
    fileToken: null,
  }),
  props: {
    photo: {
      type: Object,
      default: () => {
      },
    }
  },
  computed: {
    src() {
      return this.fileToken;
    }
  },
  methods: {
    loadPhoto() {
      if (this.photo && this.photo.avatar) {
        this.fileToken = this.$filePath(this.photo.avatar.contentUrl);
      } else {
        this.fileToken = null;
      }
    },
  },
  mounted() {
    this.loadPhoto();
  }
}
</script>
<style scoped>

.preview-center {
  text-align: center;
}

@media only screen and (max-width: 991px) {
  .preview-center {
    text-align: left;
  }
}
</style>
